import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  components: {},
  data: function data() {
    return {
      showRightList: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      data: [],
      list: [],
      buttonsList: [{
        index: 1,
        name: "全部"
      }, {
        index: 2,
        name: "积分收入"
      }, {
        index: 3,
        name: "积分支出"
      }],
      buttonIndex: "",
      companyId: "",
      // 企业id
      columns: [{
        field: "integralChange",
        label: "积分变化",
        width: "150",
        centered: true
      }, {
        field: "time",
        label: "时间",
        width: "200",
        centered: true
      }, {
        field: "remarks",
        label: "备注",
        centered: true
      }],
      total: 0,
      //总条数
      rangeBefore: 3,
      rangeAfter: 1
    };
  },
  created: function created() {
    var loginCompanyId = accountStorage.getCacheUserInfo();
    this.companyId = loginCompanyId.loginCompanyId;
    this.getPageListForPointRecord();
    this.getPageList();
  },
  methods: {
    handleChange: function handleChange(val) {
      this.currentPage = val;
      this.getPageListForPointRecord();
    },
    onSearch: function onSearch() {},
    // 列表 /api/v1/point/exportPointInfo
    getPageListForPointRecord: function getPageListForPointRecord() {
      var _this = this;
      var typeIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var data = {
        companyId: this.companyId,
        page: this.currentPage,
        pageSize: this.perPage,
        operateType: typeIndex
      };
      var url = "/pjyy-deepexi-user-center/api/v1/pointsRecord/pageListForPointRecord";
      this.$getHttpClient().post(url, data).then(function (res) {
        _this.data = res.data.data.list;
        _this.total = res.data.data.total || 0;
      });
    },
    // 积分显示
    getPageList: function getPageList() {
      var _this2 = this;
      var data = {
        companyId: this.companyId
      };
      var url = "/pjyy-deepexi-user-center/api/v1/point/pageList";
      this.$getHttpClient().post(url, data).then(function (res) {
        _this2.list = res.data.data.list;
      });
    },
    toggleTab: function toggleTab(i) {
      this.currentPage = 1;
      this.buttonIndex = i;
      if (this.buttonIndex === 0) {
        this.buttonIndex = "";
      }
      this.getPageListForPointRecord(this.buttonIndex);
    }
  }
};